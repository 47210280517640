import Vue from "vue";
import VueRouter from "vue-router";

import {
  UserUtil
} from "@/utils/user";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    redirect: "main",
  },
  {
    path: "/sign-in",
    name: "signin",
    component: () => import("../pages/login/index.vue"),
  },
  {
    path: "/main",
    name: "main",
    component: () => import("../pages/index.vue"),
    redirect: "/home",
    children: [{
      path: "/home",
      name: "home",
      component: () => import("../pages/home/home.vue"),
    }],
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

// 挂载路由守卫
router.beforeEach((to, from, next) => {
  document.title = '易点菜谱'
  let toPath = to.path;
  let hasLogged = false;
  try {
    hasLogged = (UserUtil.token() || '').length > 0;
  } catch (e) {}
  console.log(`has logged : ${hasLogged}, to : ${toPath}`);
  if (!hasLogged && toPath != "/sign-in") {
    return next("/sign-in");
  } else if (hasLogged && toPath == "/sign-in") {
    return next("/home");
  }
  return next();
});

export default router;