import { tableData } from "./table-mock-data";

import Cookies from "js-cookie";
// 加密解密
let Base64 = require("js-base64").Base64;

const TaskStatusMap = {
  0: "todo",
  1: "progress",
  2: "completed",
  "-1": "overdue",
};

export const MealTypes = [
  { value: "BREAKFAST", label: "早餐" },
  { value: "BREAKFAST_DESSERT", label: "早点" },
  { value: "LUNCH", label: "午餐" },
  { value: "LUNCH_DESSERT", label: "午点" },
  { value: "DINNER", label: "晚餐" },
  { value: "NONFOOD", label: "非食材" }
]

export const Weeks = [
  { value: "SUNDAY", label: "周日" },
  { value: "MONDAY", label: "周一" },
  { value: "TUESDAY", label: "周二" },
  { value: "WEDNESDAY", label: "周三" },
  { value: "THURSDAY", label: "周四" },
  { value: "FRIDAY", label: "周五" },
  { value: "SATURDAY", label: "六" }
]

// 政治面貌  CROWD#群众  PARTY#党员  GROUP#团员
// 户籍    TOWN#城镇  VILLAGE#农村
// 婚姻状况  UNMARRIED#未婚   MARRIED#已婚  DIVORCE#离异



let map2Target = (source, key, value) => {
  let target = {};
  let temp = (source || []).map((item) => {
    if (item[key] == value) {
      target = item;
      return target;
    }
  });
  return target;
};

export const sys_def = {
  tableData: tableData,
  MealTypes: MealTypes,
  Weeks: Weeks,

  map(source, value, key = 'value') {
    let data = this[source] || []
    return map2Target(data, key, value);
  }
};
