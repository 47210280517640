// 加密解密
let Base64 = require("js-base64").Base64;
import Cookies from "js-cookie";

const Cookie_Authorization_Key = "Authorization";
const Cookie_User_Key = "user";
const Cookie_Role_Key = "role";
const Cookie_User_Config_Key = "user_config";
const Cookie_User_School_Key = "user_school_info"

export const UserUtil = {
  token(token) {
    if (token && typeof token == 'string') {
      window.localStorage.setItem(
        Cookie_Authorization_Key,
        JSON.stringify(token)
      );
    }
    let data = window.localStorage.getItem(Cookie_Authorization_Key);
    data = JSON.parse(data);
    return data || '';
  },
  save(user) {
    let userJson = JSON.stringify(user);
    let userData = Base64.encode(userJson);
    window.localStorage.setItem(Cookie_User_Key, userData);
  },
  get() {
    let user = null;
    try {
      let userData = window.localStorage.getItem(Cookie_User_Key);
      user = JSON.parse(Base64.decode(userData));
    } catch (e) {}

    return user || {};
  },
  remove() {
    window.localStorage.removeItem(Cookie_Authorization_Key);
    window.localStorage.removeItem(Cookie_User_Key);
  },
};
